<template>
  <nav
    class="container mx-auto p-8 flex items-center justify-end gap-2 transition-colors duration-500"
  >
    <slot />

    <ul class="font-bold text-xl transition-transform duration-100 flex gap-8">
      <li class="tracking-widest">
        <a href="/">Hem</a>
      </li>
      <li class="tracking-widest">
        <a href="/om-oss">Om oss</a>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { ref } from "vue";

const isOpen = ref(false);
</script>
